import React from "react";
import PropTypes from "prop-types";

import { changeOrderStatus, OrderStatus } from "../firebase/service";
import { IDrinkOrder, IUser } from "./TopBar";
import { Checkbox } from "@material-ui/core";
import { newOrder, orderDone, orderInProgress } from "../colors";

export interface IProcessedOrder {
  user: IUser;
  table: string;
  drinks: IDrinkOrder[];
  status: OrderStatus;
  createdAt: Date;
  id: string;
}

function OrderView({
  order,
  editable,
}: {
  order: IProcessedOrder;
  editable?: boolean;
}) {
  let total: number = 0;
  order.drinks.forEach((drink) => {
    total += drink.count * drink.price;
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
      ? OrderStatus.Done
      : OrderStatus.Processing;
    // var newValue: OrderStatus = OrderStatus[event.target.value as typeof string as keyof OrderStatus];

    await changeOrderStatus(
      {
        ...order,
        user: order.user.id,
        drinks: order.drinks.map(({ id, count }) => ({
          id,
          count,
        })),
      },
      newValue
    );
  };

  const getMapping = (status: string) => {
    const mapping: any = {
      PROCESSING: "BESTELD",
      IN_PROGRESS: "IN BEHANDELING",
      DONE: "KLAAR",
    };
    return mapping[status];
  };

  const colorMapping = (status: string) => {
    const mapping: any = {
      PROCESSING: newOrder,
      IN_PROGRESS: orderInProgress,
      DONE: orderDone,
    };
    return mapping[status];
  };

  const color = colorMapping(order.status);

  return (
    <div
      style={{
        border: `solid 1px`,
        borderColor: color,
        padding: 10,
        marginBottom: 20,
        color,
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ textAlign: "left", flex: 1 }}>Tafel #{order.table}</div>
        <div style={{ textAlign: "right", flex: 1 }}>
          {editable ? (
            <Checkbox
              checked={order.status === OrderStatus.Done}
              onChange={handleChange}
              color="primary"
            />
          ) : (
            getMapping(order.status)
          )}
        </div>
      </div>
      <div style={{ display: "flex", paddingBottom: 20 }}>
        <div style={{ textAlign: "left", flex: 1 }}>
          {order.user.displayName}
        </div>
        <div style={{ textAlign: "right", flex: 1 }}>
          {order.createdAt.toDateString().split(" ").slice(1, 3).join(" ")}{" "}
          {order.createdAt.toTimeString().split(" ")[0]}
        </div>
      </div>

      {order.drinks.map((drink) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={drink.id}
        >
          <span style={{ flex: 1, textAlign: "left" }}>
            {drink.displayName} x {drink.count}
          </span>
          <span style={{ flex: 1, textAlign: "right" }}>
            {drink.count * drink.price} €
          </span>
        </div>
      ))}
      <hr
        style={{
          borderTopColor: color,
          borderBottomColor: color,
          borderColor: color,
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ flex: 1, textAlign: "left" }}>TOTAL</span>
        <span style={{ flex: 1, textAlign: "right" }}>{total} €</span>
      </div>
    </div>
  );
}

OrderView.propTypes = {
  order: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default OrderView;
