import React from "react";
import PropTypes from "prop-types";
import { IDrink } from "../components/TopBar";
import DrinksCategory from "../components/DrinksCategory";

function Prices({ drinks }: { drinks: IDrink[] }) {
  const drinksPerCategory: { [key: string]: IDrink[] } = {
    BEER: [],
    SODA: [],
    WINE: [],
  };
  Object.keys(drinksPerCategory).forEach((category) => {
    drinksPerCategory[category] = drinks.filter(
      (drink) => drink.category === category
    );
  });

  const categoryName = (category: string): string => {
    if (category === "BEER" || category === "SODA" || category === "WIJN") {
      return {
        BEER: "BIER",
        SODA: "FRIS",
        WIJN: "FANCY",
      }[category];
    }
    return "OVERIGE";
  };

  return (
    <>
      {Object.keys(drinksPerCategory).map((category) => {
        return drinksPerCategory[category].length ? (
          <DrinksCategory
            key={category}
            name={categoryName(category)}
            drinks={drinksPerCategory[category]}
          />
        ) : null;
      })}
    </>
  );
}

Prices.propTypes = {
  drinks: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default Prices;
