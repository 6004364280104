import React from "react";
import logo from "./logo.jpg";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

import "./App.css";
import { auth, signInWithGoogle } from "./firebase/firebase.utils";
import {
  convertDrinksFromFirestore,
  convertOrderFromFirestore,
  convertUserFromFirestore,
  createUserProfileDocument,
  getDrinksRef,
  getMyOrdersRef,
  getOrdersRef,
  getUserData,
  getUsersRef,
  IOrder,
} from "./firebase/service";
import { Unsubscribe } from "firebase";
import TopBar, { IDrink, IUser } from "./components/TopBar";
import Users from "./pages/Users";
import { CollectionReference, Query } from "@firebase/firestore-types";
import Prices from "./pages/Prices";
import Order from "./pages/Order";
import { Container, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Orders from "./pages/Orders";
import UserInfo from "./pages/UserInfo";
import { primary, secondary } from "./colors";

function Home() {
  return (
    <div>
      <p>
        Welkom bij in't Wilde Weg, de gezelligste zomerbar van Destelbergen!
      </p>

      <p>
        Jullie zijn elke vrijdagavond vanaf 17 uur tot middernacht welkom in
        onze tuin. Tot dan!
      </p>
    </div>
  );
}

function Thanks() {
  return (
    <div>
      <h3>Bedankt voor de bestelling! We komen snel naar je tafel!</h3>
    </div>
  );
}

export const theme = createMuiTheme({
  palette: { primary: { main: primary }, secondary: { main: secondary } },
});

interface IProps {}

interface IState {
  currentUser: IUser | null;
  users: IUser[];
  drinks: IDrink[];
  orders: IOrder[];
  myOrders: IOrder[];
  loaded: boolean;
}

class App extends React.Component<IProps, IState> {
  unsubscribeFromAuth: Unsubscribe | null = null;
  usersRef: CollectionReference | null = null;
  drinksRef: CollectionReference | null = null;
  ordersRef: CollectionReference | null = null;
  myOrdersRef: Query | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      currentUser: null,
      users: [],
      drinks: [],
      orders: [],
      myOrders: [],
      loaded: false,
    };
  }

  componentDidMount() {
    this.drinksRef = getDrinksRef();
    this.drinksRef.onSnapshot((snapShot) => {
      const drinks = snapShot.docs.map(convertDrinksFromFirestore);
      this.setState({
        ...this.state,
        drinks,
      });
    });

    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth, {});
        const user = await getUserData(userAuth);

        if (
          user &&
          user.role &&
          (user.role === "BAR" || user.role === "ADMIN")
        ) {
          this.usersRef = getUsersRef();
          this.usersRef.onSnapshot((snapShot) => {
            const users = snapShot.docs.map(convertUserFromFirestore);
            this.setState({
              ...this.state,
              users,
            });
          });

          this.ordersRef = getOrdersRef();
          this.ordersRef.onSnapshot((snapShot) => {
            const orders = snapShot.docs.map(convertOrderFromFirestore);
            this.setState({
              ...this.state,
              orders,
            });
          });
        }

        this.myOrdersRef = getMyOrdersRef(userAuth);
        if (this.myOrdersRef) {
          this.myOrdersRef.onSnapshot((snapShot) => {
            const myOrders = snapShot.docs.map(convertOrderFromFirestore);
            this.setState({
              ...this.state,
              myOrders,
            });
          });
        }

        if (userRef) {
          userRef.onSnapshot((snapShot) => {
            const data = snapShot.data();
            if (data) {
              this.setState({
                ...this.state,
                loaded: true,
                currentUser: {
                  id: snapShot.id,
                  displayName: data.displayName,
                  role: data.role,
                  email: data.email,
                  createdAt: data.createdAt,
                },
              });
            } else {
              console.error("Did not get user data");
              this.setState({
                ...this.state,
                loaded: true,
                currentUser: {
                  id: snapShot.id,
                  displayName: "None",
                  role: "USER",
                  email: "",
                  createdAt: new Date(),
                },
              });
            }
          });
        }
      } else {
        this.setState({
          ...this.state,
          loaded: true,
          currentUser: null,
          orders: [],
          myOrders: [],
          users: [],
        });
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribeFromAuth) {
      this.unsubscribeFromAuth();
    }
  }

  logout = () => {
    auth.signOut();
  };

  getAdminRoutes = () => {
    if (this.state.currentUser) {
      if (this.state.currentUser.role === "ADMIN") {
        return [
          <Route exact path="/gebruikers" key={"gebruikers"}>
            <Users users={this.state.users} />
          </Route>,
        ];
      }
    }
  };

  getUserRoutes = () => {
    if (this.state.currentUser) {
      if (
        this.state.currentUser.role === "USER" ||
        this.state.currentUser.role === "BAR" ||
        this.state.currentUser.role === "ADMIN"
      ) {
        return [
          <Route exact path="/bestel" key={"bestel"}>
            <Order drinks={this.state.drinks} />
          </Route>,
          <Route exact path="/gebruiker" key={"gebruiker"}>
            <UserInfo
              drinks={this.state.drinks}
              orders={this.state.myOrders}
              users={[this.state.currentUser]}
              onLogout={this.logout}
            />
          </Route>,
        ];
      }
    }
  };

  getNoUserRoutes = () => {
    console.log(this.state.currentUser, this.state.currentUser === null);
    if (this.state.currentUser === null) {
      return [
        <Route exact path="/bestel" key={"bestel"}>
          <h3>
            Momenteel niet ingelogd. Log in om te bestellen en/of je
            bestellingen te zien
          </h3>
        </Route>,
        <Route exact path="/gebruiker" key={"gebruiker"}>
          <h3>
            Momenteel niet ingelogd. Log in om te bestellen en/of bestellingen
            te zien
          </h3>
        </Route>,
      ];
    }
  };

  getBarRoutes = () => {
    if (this.state.currentUser) {
      if (
        this.state.currentUser.role === "BAR" ||
        this.state.currentUser.role === "ADMIN"
      ) {
        return [
          <Route exact path="/bestellingen" key={"bestellingen"}>
            <Orders
              drinks={this.state.drinks}
              orders={this.state.orders}
              users={this.state.users}
              editable
            />
          </Route>,
        ];
      }
    }
  };

  render() {
    if (!this.state.loaded) {
      return (
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <header className="App-header" />

            <Container maxWidth="sm" className="App-contents">
              <div>
                <h2>Laden...</h2>
              </div>
            </Container>
          </div>
        </MuiThemeProvider>
      );
    }
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <TopBar
              currentUser={this.state.currentUser}
              onLogin={signInWithGoogle}
              onLogout={this.logout}
            />
            <header className="App-header">
              <Link to="/">
                <img src={logo} className="App-logo" alt="logo" />
              </Link>
            </header>

            <Container maxWidth="sm" className="App-contents">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/bedankt">
                  <Thanks />
                </Route>
                <Route path="/prijskaart">
                  <Prices drinks={this.state.drinks} />
                </Route>
                {this.getNoUserRoutes()}
                {this.getAdminRoutes()}
                {this.getUserRoutes()}
                {this.getBarRoutes()}
                <Route>
                  <p>Pagina bestaat niet</p>
                </Route>
              </Switch>
            </Container>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
