import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyD2u273ODJSlwWj-x7FSAfaZHWc0q7Ymdg",
  authDomain: "twildeweg.firebaseapp.com",
  databaseURL: "https://twildeweg.firebaseio.com",
  projectId: "twildeweg",
  storageBucket: "twildeweg.appspot.com",
  messagingSenderId: "854531594003",
  appId: "1:854531594003:web:24b7f5e584239866acece6",
  measurementId: "G-T03KBQJYRH",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

// google authentication utility
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithRedirect(provider);

export default firebase;
