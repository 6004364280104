import { IDrink, IDrinkOrder } from "../components/TopBar";

import React, { SyntheticEvent } from "react";
import OrderTotal from "../components/OrderTotal";
import DrinkOrdersCategory from "../components/DrinkOrdersCategory";
import { Button, TextField } from "@material-ui/core";
import styled from "styled-components"; // let's also import Component
import { useHistory } from "react-router-dom";

import { makeOrder, OrderStatus } from "../firebase/service";
import { auth } from "../firebase/firebase.utils";
import { orderDone, orderInProgress, primary } from "../colors";
import DrinksCategory from "../components/DrinksCategory";

// the clock's state has one field: The current time, based upon the
// JavaScript class Date
type OrderState = {
  orderDrinks: IDrinkOrder[];
  tableNumber: string;
};

type OrderProps = {
  drinks: IDrink[];
};

const StyledButton = styled(Button)`
  background-color: ${primary};
  border-radius: 5px;
  color: white;
  height: 48px;
  padding: 10px 15px;
  margin: 5px;
  font-size: 1em;
`;

function OrderFunction({ drinks }: OrderProps) {
  const history = useHistory();
  const [tableNumber, setTableNumber] = React.useState("");
  const [orderDrinks, setOrderDrinks] = React.useState(
    drinks.map((drink) => ({
      ...drink,
      count: 0,
    }))
  );

  const continueToNextPage = () => {
    history.push("/bedankt");
  };

  const addDrink = (drinkName: string) => {
    const newDrinks = orderDrinks.map((drink) => {
      if (drink.displayName === drinkName) {
        drink.count += 1;
      }
      return drink;
    });
    setOrderDrinks(newDrinks);
  };

  const removeDrink = (drinkName: string) => {
    const newDrinks = orderDrinks.map((drink) => {
      if (drink.displayName === drinkName) {
        drink.count -= 1;
        if (drink.count < 0) {
          drink.count = 0;
        }
      }
      return drink;
    });

    setOrderDrinks(newDrinks);
  };

  const changedTableNumber = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setTableNumber(value);
  };

  const orderTheDrinks = async () => {
    const drinksWithCount = orderDrinks.filter((drink) => drink.count > 0);

    const validTable = tableNumber !== "" && parseInt(tableNumber) < 50;

    if (validTable && drinksWithCount.length > 0) {
      const user = auth.currentUser;
      await makeOrder(user, {
        status: OrderStatus.Processing,
        table: tableNumber,
        drinks: drinksWithCount.map((drink) => ({
          id: drink.id,
          count: drink.count,
        })),
      });
      continueToNextPage();
    }
  };

  const drinksPerCategory: { [key: string]: IDrinkOrder[] } = {
    BEER: [],
    SODA: [],
    WINE: [],
  };

  Object.keys(drinksPerCategory).forEach((category) => {
    drinksPerCategory[category] = orderDrinks.filter(
      (drink) => drink.category === category
    );
  });

  const categoryName = (category: string): string => {
    if (category === "BEER" || category === "SODA" || category === "WIJN") {
      return {
        BEER: "BIER",
        SODA: "FRIS",
        WIJN: "FANCY",
      }[category];
    }
    return "OVERIGE";
  };

  return (
    <div>
      {Object.keys(drinksPerCategory).map((category) => {
        return drinksPerCategory[category].length ? (
          <DrinkOrdersCategory
            key={category}
            name={categoryName(category)}
            drinks={drinksPerCategory[category]}
            onAdd={addDrink}
            onRemove={removeDrink}
          />
        ) : null;
      })}
      <h2 style={{ paddingTop: 25 }}>BESTELLING</h2>

      <OrderTotal drinks={orderDrinks} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 20,
        }}
      >
        <TextField
          required
          type="number"
          id="standard-basic"
          label="Tafelnummer"
          style={{ color: "green" }}
          value={tableNumber}
          onChange={changedTableNumber}
        />
        <StyledButton
          variant={"contained"}
          color={"primary"}
          onClick={orderTheDrinks}
          style={{ marginBottom: 100 }}
        >
          BESTEL!
        </StyledButton>
      </div>
    </div>
  );
}

export default OrderFunction;
