import React from "react";
import PropTypes from "prop-types";
import DrinkOrder from "./DrinkOrder";
import { IDrinkOrder } from "./TopBar";

function DrinkOrdersCategory({
  name,
  drinks,
  onAdd,
  onRemove,
}: {
  name: string;
  drinks: IDrinkOrder[];
  onAdd: (name: string) => void;
  onRemove: (name: string) => void;
}) {
  drinks.sort((a, b) => a.price - b.price);
  return (
    <div className="list-items">
      <h2>{name.toUpperCase()}</h2>
      {drinks.map(({ id, displayName, price, alcohol, count }) => (
        <DrinkOrder
          key={id}
          count={count}
          displayName={displayName}
          price={price}
          alcohol={alcohol}
          onAdd={onAdd}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
}

export interface DrinkData {
  name: string;
  alcohol?: number;
  price: number;
}

DrinkOrdersCategory.propTypes = {
  name: PropTypes.string,
  drinks: PropTypes.arrayOf(PropTypes.shape(DrinkOrder.propTypes)).isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default DrinkOrdersCategory;
