import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DrinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

function Drink({
  displayName,
  price,
  alcohol,
}: {
  displayName: string;
  price: number;
  alcohol?: number;
}) {
  return (
    <DrinkContainer>
      <p style={{ textAlign: "left", flex: 1 }}>{displayName}</p>
      <p style={{ textAlign: "center", flex: 1 }}>{`${
        alcohol ? `${alcohol}%` : "-"
      }`}</p>
      <p style={{ textAlign: "right", flex: 1 }}>{price} €</p>
    </DrinkContainer>
  );
}

Drink.propTypes = {
  displayName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  alcohol: PropTypes.number,
};

export default Drink;
