import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";

const DrinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

function DrinkOrder({
  displayName,
  price,
  alcohol,
  count,
  onAdd,
  onRemove,
}: {
  displayName: string;
  price: number;
  alcohol?: number;
  count: number;
  onAdd: (name: string) => void;
  onRemove: (name: string) => void;
}) {
  function addDrink() {
    onAdd(displayName);
  }

  function removeDrink() {
    onRemove(displayName);
  }

  return (
    <DrinkContainer>
      <span style={{ textAlign: "left", flex: 1 }}>
        {displayName} ({price}€)
      </span>

      <span style={{ textAlign: "right", width: "100px" }}>
        <IconButton
          style={{ paddingRight: 10 }}
          size={"small"}
          aria-label="remove drink"
          color="primary"
          disabled={count <= 0}
          onClick={removeDrink}
        >
          <IndeterminateCheckBoxOutlinedIcon />
        </IconButton>
        <span style={{ textAlign: "center", width: "30px" }}>{count}</span>

        <IconButton
          size={"small"}
          aria-label="add drink"
          color="primary"
          style={{ paddingLeft: 10 }}
          onClick={addDrink}
        >
          <AddBoxOutlinedIcon />
        </IconButton>
      </span>
    </DrinkContainer>
  );
}

DrinkOrder.propTypes = {
  displayName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  alcohol: PropTypes.number,
  count: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default DrinkOrder;
