import React, { SyntheticEvent } from "react";
import PropTypes from "prop-types";
import OrderView, { IProcessedOrder } from "../components/OrderView";
import { IDrink, IDrinkOrder, IUser } from "../components/TopBar";
import { IOrder, OrderStatus } from "../firebase/service";
import { Button, TextField } from "@material-ui/core";

function UserInfo({
  orders,
  users,
  drinks,
  editable,
  onLogout,
}: {
  orders: IOrder[];
  users: IUser[];
  drinks: IDrink[];
  editable?: boolean;
  onLogout: () => void;
}) {
  const [tableNumberFilter, setTableNumberFilter] = React.useState("");

  const filteredOrders = orders.filter(
    (order) => tableNumberFilter === "" || order.table === tableNumberFilter
  );

  const processedOrders: IProcessedOrder[] = filteredOrders.map((order) => {
    const user = users.find((user) => user.id === order.user);

    const mappedDrinks = order.drinks.map((drink) => {
      const foundDrink = drinks.find(
        (otherDrink) => otherDrink.id === drink.id
      );
      if (foundDrink) {
        return {
          ...foundDrink,
          count: drink.count,
        };
      } else {
        throw Error("Could not find drink for id");
      }
    });
    if (user && mappedDrinks) {
      return {
        ...order,
        user: user,
        drinks: mappedDrinks,
      };
    } else {
      throw Error(
        `Could not find error for user, something is wrong... ${user} ${mappedDrinks}`
      );
    }
  });

  const changedTableNumberFilter = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setTableNumberFilter(value);
  };

  processedOrders.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  if (processedOrders.length === 0) {
    return (
      <div>
        <h1 style={{ textAlign: "center" }}>
          {editable ? "Bestellingen" : "Mijn bestellingen"}
        </h1>
        {editable ? (
          <TextField
            required
            type="number"
            id="standard-basic"
            label="Tafelnummer"
            style={{ color: "green", marginBottom: 50 }}
            value={tableNumberFilter}
            onChange={changedTableNumberFilter}
          />
        ) : null}
        <h4>Geen bestellingen gevonden</h4>
      </div>
    );
  }

  const getAllDrinks = (myOrders: IProcessedOrder[]): IDrinkOrder[] => {
    const allDrinks: Record<string, IDrinkOrder> = {};
    myOrders.forEach(({ drinks, status }) => {
      if (status === OrderStatus.Done) {
        return;
      }
      drinks.forEach((drink) => {
        if (allDrinks.hasOwnProperty(drink.id)) {
          allDrinks[drink.id].count += drink.count;
        } else {
          allDrinks[drink.id] = {
            ...drink,
          };
        }
      });
    });
    return Object.values(allDrinks);
  };

  const getGeneralStatus = (myOrders: IProcessedOrder[]): OrderStatus => {
    if (myOrders.every((order) => order.status === OrderStatus.Done)) {
      return OrderStatus.Done;
    }
    return OrderStatus.Processing;
  };

  let orderTotal: IProcessedOrder | null = null;
  if (tableNumberFilter !== "") {
    orderTotal = {
      id: "total",
      createdAt: new Date(),
      table: tableNumberFilter,
      user: {
        id: "table",
        role: "USER",
        createdAt: new Date(),
        email: "dummy@dummy.io",
        displayName: `AFREKENING TAFEL`,
      },
      drinks: getAllDrinks(processedOrders),
      status: getGeneralStatus(processedOrders),
    };
  }

  const user = users[0];

  return (
    <div>
      <h1>Gebruiker</h1>
      <div>
        <p>Ingelogd als {user.displayName}</p>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onLogout}
          style={{ color: "white", marginLeft: 15 }}
        >
          Log out
        </Button>
      </div>
      <h1 style={{ textAlign: "center" }}>
        {editable ? "Bestellingen" : "Mijn bestellingen"}
      </h1>
      {editable ? (
        <TextField
          required
          type="number"
          id="standard-basic"
          label="Tafelnummer"
          style={{ color: "green", marginBottom: 50 }}
          value={tableNumberFilter}
          onChange={changedTableNumberFilter}
        />
      ) : null}
      {orderTotal ? (
        <>
          <h4>Tafel {tableNumberFilter} totaal onafgehandeld</h4>
          <OrderView order={orderTotal} key={orderTotal.id} />
          <h4>Alle bestellingen van tafel {tableNumberFilter}</h4>
        </>
      ) : null}
      {processedOrders.map((processedOrder) => (
        <OrderView
          order={processedOrder}
          editable={editable}
          key={processedOrder.id}
        />
      ))}
    </div>
  );
}

UserInfo.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object.isRequired),
  drinks: PropTypes.arrayOf(PropTypes.object.isRequired),
  orders: PropTypes.arrayOf(PropTypes.object.isRequired),
  editable: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
};

export default UserInfo;
