import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, Route, Switch } from "react-router-dom";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { primary } from "../colors";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import AccountBoxTwoToneIcon from "@material-ui/icons/AccountBoxTwoTone";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import LocalBarTwoToneIcon from "@material-ui/icons/LocalBarTwoTone";
export interface IDrink {
  id: string;
  displayName: string;
  price: number;
  alcohol?: number;
  category: string;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${primary};
`;

const StyledIconLink = styled(Link)`
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  color: ${primary};
`;

export interface IDrinkOrder {
  id: string;
  displayName: string;
  price: number;
  alcohol?: number;
  category: string;
  count: number;
}

export interface IUser {
  id: string;
  displayName: string;
  role: string;
  email: string;
  createdAt: Date;
}

const StyledButton = styled(Button)`
  color: white;
  margin-left: 5px;
`;

function TopBar({
  currentUser,
  onLogin,
  onLogout,
}: {
  currentUser: IUser | null;
  onLogin: () => void;
  onLogout: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBarRoutes = (currentUser: IUser | null) => {
    return [
      <StyledLink to="/bestellingen" key={"bestellingen"}>
        <MenuItem onClick={handleClose}>Bestellingen</MenuItem>
      </StyledLink>,
    ];
  };

  const getUserRoutes = (currentUser: IUser | null) => {
    return [
      <StyledLink to="/bestel" key={"bestel"}>
        <MenuItem onClick={handleClose}>Bestel</MenuItem>
      </StyledLink>,
      <StyledLink to="/gebruiker" key={"gebruiker"}>
        <MenuItem onClick={handleClose}>Mijn bestellingen</MenuItem>
      </StyledLink>,
    ];
  };

  const getAdminRoutes = (currentUser: IUser | null) => {
    return [
      <StyledLink to="/gebruikers" key={"gebruikers"}>
        <MenuItem onClick={handleClose}>Gebruikers</MenuItem>
      </StyledLink>,
    ];
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "white" }}>
      <Toolbar>
        <IconButton
          edge="start"
          style={{
            marginRight: 5,
          }}
          color="primary"
          aria-label="menu"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledLink to="/">
            <MenuItem onClick={handleClose}>Over</MenuItem>
          </StyledLink>
          <StyledLink to="/prijskaart">
            <MenuItem onClick={handleClose}>Prijskaart</MenuItem>
          </StyledLink>
          {currentUser &&
          (currentUser.role === "USER" ||
            currentUser.role === "BAR" ||
            currentUser.role === "ADMIN") ? (
            <hr />
          ) : null}
          {currentUser &&
          (currentUser.role === "USER" ||
            currentUser.role === "BAR" ||
            currentUser.role === "ADMIN")
            ? getUserRoutes(currentUser)
            : null}
          {currentUser &&
          (currentUser.role === "ADMIN" || currentUser.role === "BAR") ? (
            <hr />
          ) : null}
          {currentUser &&
          (currentUser.role === "ADMIN" || currentUser.role === "BAR")
            ? getBarRoutes(currentUser)
            : null}
          {currentUser && currentUser.role === "ADMIN" ? <hr /> : null}
          {currentUser && currentUser.role === "ADMIN"
            ? getAdminRoutes(currentUser)
            : null}
        </Menu>
        <Switch>
          <Route exact path="/">
            In't Wilde Weg
          </Route>
          <Route exact path="/bedankt">
            Bedankt
          </Route>
          <Route path="/prijskaart">Prijskaart</Route>
          <Route path="/bestel">Bestel</Route>
          <Route path="/bedankt">Bedankt!</Route>
          <Route path="/bedankt">Bedankt!</Route>
          <Route path="/bestellingen">Bestellingen</Route>
          <Route path="/gebruikers">Gebruikers</Route>
          <Route path="/gebruiker">Gebruiker</Route>
          <Route>
            <p>Onbekende pagina</p>
          </Route>
        </Switch>
        <div style={{ flex: 1 }} />
        <StyledIconLink to="/prijskaart">
          <LocalBarTwoToneIcon color="primary" />
        </StyledIconLink>
        <StyledIconLink to="/bestel">
          <StorefrontTwoToneIcon color="primary" />
        </StyledIconLink>
        {currentUser === null ? (
          <StyledButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={onLogin}
            style={{ color: primary, marginLeft: 15 }}
            startIcon={<AccountBoxIcon color="primary" />}
          >
            Login
          </StyledButton>
        ) : (
          <StyledIconLink to="/gebruiker">
            <AccountBoxIcon color="primary" />
          </StyledIconLink>
        )}
        {/*<Button color="inherit">Login</Button>*/}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  currentUser: PropTypes.object,
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default TopBar;
