import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControl, Select, MenuItem } from "@material-ui/core";

const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

function User({
  id,
  displayName,
  role,
  createdAt,
  email,
  changeRole,
}: {
  id: string;
  displayName: string;
  role: string;
  createdAt: Date;
  email: string;
  changeRole: (id: string, value: string) => void;
}) {
  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (typeof event.target.value === "string") {
      changeRole(id, event.target.value);
    }
  };

  return (
    <>
      <UserContainer>
        <p style={{ textAlign: "left", flex: 1 }}>{displayName}</p>
        <div style={{ textAlign: "right", flex: 1 }}>
          <FormControl style={{}}>
            <Select
              labelId="demo-simple-select-label"
              value={role}
              onChange={handleChange}
            >
              <MenuItem value={"USER"}>USER</MenuItem>
              <MenuItem value={"BAR"}>BAR</MenuItem>
              <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
            </Select>
          </FormControl>
        </div>
      </UserContainer>
      <UserContainer style={{ marginTop: -25 }}>
        <p style={{ textAlign: "left", flex: 1 }}>{email}</p>
        <p style={{ textAlign: "right", flex: 1 }}>
          {createdAt.toDateString()}
        </p>
      </UserContainer>
    </>
  );
}

User.propTypes = {
  displayName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  createdAt: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  changeRole: PropTypes.func.isRequired,
};

export default User;
