import React from "react";
import PropTypes from "prop-types";
import Drink from "./Drink";
import { IDrink } from "./TopBar";

function DrinksCategory({ name, drinks }: { name: string; drinks: IDrink[] }) {
  drinks.sort((a, b) => a.price - b.price);
  return (
    <div className="list-items">
      <h2>{name.toUpperCase()}</h2>
      {drinks.map(({ id, displayName, price, alcohol }) => (
        <Drink
          key={id}
          displayName={displayName}
          price={price}
          alcohol={alcohol}
        />
      ))}
    </div>
  );
}

export interface DrinkData {
  name: string;
  alcohol?: number;
  price: number;
}

DrinksCategory.propTypes = {
  name: PropTypes.string,
  drinks: PropTypes.arrayOf(PropTypes.shape(Drink.propTypes)).isRequired,
};

export default DrinksCategory;
