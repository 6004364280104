import React from "react";
import PropTypes from "prop-types";
import { IDrinkOrder } from "./TopBar";

function OrderTotal({ drinks }: { drinks: IDrinkOrder[] }) {
  const drinksWithCount = drinks.filter((drink) => drink.count > 0);

  let total: number = 0;
  drinksWithCount.forEach((drink) => {
    total += drink.count * drink.price;
  });

  return (
    <div>
      {drinksWithCount.map((drink) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={drink.id}
        >
          <span style={{ flex: 1, textAlign: "left" }}>
            {drink.displayName} x {drink.count}
          </span>
          <span style={{ flex: 1, textAlign: "right" }}>
            {drink.count * drink.price} €
          </span>
        </div>
      ))}
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span style={{ flex: 1, textAlign: "left" }}>TOTAL</span>
        <span style={{ flex: 1, textAlign: "right" }}>{total} €</span>
      </div>

      {/* TODO
    Add table address and add order button
    */}
    </div>
  );
}

OrderTotal.propTypes = {
  drinks: PropTypes.array.isRequired,
};

export default OrderTotal;
