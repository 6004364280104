import { User } from "firebase";
import { firestore } from "./firebase.utils";
import { IDrink, IUser } from "../components/TopBar";
import {
  CollectionReference,
  Query,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import { IProcessedOrder } from "../components/OrderView";
import React from "react";

export const createUserProfileDocument = async (
  userAuth: User | null,
  additionalData: object
) => {
  if (!userAuth) {
    return;
  }

  const userRef = firestore.doc(`/users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        role: "USER",
        ...additionalData,
      });
    } catch (error) {
      console.log("Error creating user", error.message);
    }
  }

  return userRef;
};

export const getUserData = async (userAuth: User | null) => {
  if (!userAuth) {
    return;
  }

  const userRef = firestore.doc(`/users/${userAuth.uid}`);

  const snapShot = await userRef.get();
  if (snapShot) {
    const data = snapShot.data();
    if (data) {
      return {
        id: snapShot.id,
        displayName: data.displayName,
        createdAt: data.createdAt.toDate(),
        email: data.email,
        role: data.role,
      };
    }
  }
};

export function convertUserFromFirestore(
  snapShot: QueryDocumentSnapshot
): IUser {
  const data = snapShot.data();
  return {
    id: snapShot.id,
    displayName: data.displayName,
    createdAt: data.createdAt.toDate(),
    email: data.email,
    role: data.role,
  };
}

export function convertDrinksFromFirestore(
  snapShot: QueryDocumentSnapshot
): IDrink {
  const data = snapShot.data();
  const alcohol = data.alcohol ? data.alcohol : null;

  return {
    id: snapShot.id,
    displayName: data.displayName,
    price: data.price,
    alcohol,
    category: data.category,
  };
}

export function convertOrderFromFirestore(
  snapShot: QueryDocumentSnapshot
): IOrder {
  const data = snapShot.data();

  return {
    id: snapShot.id,
    table: data.table,
    drinks: data.drinks,
    status: data.status,
    user: data.user,
    createdAt: data.createdAt.toDate(),
  };
}

export const getUsers = async (userAuth: User | null): Promise<IUser[]> => {
  if (!userAuth) {
    return [];
  }

  const usersRef = firestore.collection(`/users`);
  const snapShot = await usersRef.get();
  const users: IUser[] = snapShot.docs.map(convertUserFromFirestore);
  console.log(users);
  return users;
  // usersRef.onSnapshot((snapShot) => {
  //   return users;
  // });
};

const getRef = (collection: string): CollectionReference => {
  return firestore.collection(collection);
};

export const getUsersRef = (): CollectionReference => {
  return getRef("/users");
};

export const getDrinksRef = (): CollectionReference => {
  return getRef("/drinks");
};

export const getDrinks = async () => {};

export const changeUserRole = async (user: IUser, role: string) => {
  const userRef = firestore.doc(`/users/${user.id}`);
  try {
    await userRef.set({
      ...user,
      role: role,
    });
  } catch (error) {
    console.log("Error creating user", error.message);
  }
};

export const changeOrderStatus = async (
  order: IOrder,
  newStatus: OrderStatus
) => {
  const orderRef = firestore.doc(`/orders/${order.id}`);
  try {
    await orderRef.set({
      ...order,
      status: newStatus,
    });
  } catch (error) {
    console.log("Error creating user", error.message);
  }
};

export const getOrdersRef = (): CollectionReference => {
  return getRef("/orders");
};

export const getMyOrdersRef = (userAuth: User): Query => {
  return getRef("/orders").where("user", "==", userAuth.uid);
};

export enum OrderStatus {
  Processing = "PROCESSING",
  InProgress = "IN_PROGRESS",
  Done = "DONE",
}

export interface IOrderDrink {
  id: string;
  count: number;
}

export interface IOrderSendData {
  table: string;
  drinks: IOrderDrink[];
  status: OrderStatus;
}

export interface IOrder {
  table: string;
  drinks: IOrderDrink[];
  status: OrderStatus;
  user: string;
  createdAt: Date;
  id: string;
}

export const makeOrder = async (
  userAuth: User | null,
  orderData: IOrderSendData
) => {
  if (!userAuth) {
    return;
  }

  const orderRef = getOrdersRef();
  const createdAt = new Date();

  try {
    await orderRef.add({
      user: userAuth.uid,
      ...orderData,
      createdAt,
    });
  } catch (error) {
    console.log("Error creating order", error.message);
  }

  return orderRef;
};

export const getOrdersForUser = async (
  userAuth: User | null
): Promise<IOrder[]> => {
  if (!userAuth) {
    return [];
  }

  const orderRef = getOrdersRef();
  const snapShot = await orderRef.where("user", "==", userAuth.uid).get();
  const orders: IOrder[] = snapShot.docs.map(convertOrderFromFirestore);
  console.log(orders);
  return orders;
};

export const getAllOrders = async (
  userAuth: User | null
): Promise<IOrder[]> => {
  if (!userAuth) {
    return [];
  }

  const orderRef = getOrdersRef();
  const snapShot = await orderRef.get();
  const orders: IOrder[] = snapShot.docs.map(convertOrderFromFirestore);
  console.log(orders);
  return orders;
};
