import React from "react";
import PropTypes from "prop-types";
import User from "../components/User";
import { IUser } from "../components/TopBar";
import { changeUserRole } from "../firebase/service";

function Users({ users }: { users: IUser[] }) {
  const updateUser = async (id: string, role: string) => {
    let changedUser;
    users.forEach((user) => {
      if (user.id === id) {
        changedUser = user;
      }
    });
    if (changedUser) {
      await changeUserRole(changedUser, role);
    }
  };

  const sortedUsers = users.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
  );
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Users</h1>
      {users.map((user) => (
        <User key={user.id} {...user} changeRole={updateUser} />
      ))}
    </div>
  );
}

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default Users;
